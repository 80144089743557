import React, { Component } from 'react';

class Projects extends Component {
  render() {
    return (
      <div>
        <div className="text-container">
          <h1>Projects</h1>
          <p>Coming soon!</p>
        </div>
      </div>
    )
  }
}
export default Projects;