import React, { Component } from 'react';

class Contact extends Component {
  render() {
    return (
      <div>
        <div className="text-container">
          <h1>Contact me</h1>
          <p>Coming soon! Send me an email at makiabad@ualberta.ca!</p>
        </div>
      </div>
    )
  }
}
export default Contact;
